var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "GenderSelectInput",
    _vm._g(
      _vm._b(
        { attrs: { items: _vm.availableGenderOptions, value: _vm.value } },
        "GenderSelectInput",
        _vm.attrs,
        false
      ),
      _vm.listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }