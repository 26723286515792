<template>
    <GenderSelectInput
        v-bind="attrs"
        v-on="listeners"
        :items="availableGenderOptions"
        :value="value"
    />
</template>

<script>
import GenderSelectInput from '@/shared/components/inputs/GenderSelectInput'

export default {
    components: { GenderSelectInput },
    props: {
        value: String,
    },
    computed: {
        availableGenderOptions() {
            return [
                { label: this.$t('inputs.GenderSelectInput.male')      , type: this.$constants.getConstant('GENDER_OPTION_MALE')       },
                { label: this.$t('inputs.GenderSelectInput.female')    , type: this.$constants.getConstant('GENDER_OPTION_FEMALE')     },
                { label: this.$t('inputs.GenderSelectInput.others')    , type: this.$constants.getConstant('GENDER_OPTION_OTHERS')     },
                { label: this.$t('inputs.GenderSelectInput.not_answer'), type: this.$constants.getConstant('GENDER_OPTION_NOT_ANSWER') },
            ]
        },
        /**
         * Extrai todos os listeners deste componente, exceto os que estamos sobrescrevendo
         */
        listeners() {
            const {  ...listeners } = this.$listeners
            return listeners
        },
        /**
         * Extrai todas as props deste componente, exceto as que estamos sobrescrevendo
         */
        attrs() {
            const { ...attrs } = this.$attrs
            return { ...attrs }
        },
    }
}
</script>