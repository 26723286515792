<template>
    <cp-select-field
        v-bind="attrs"
        v-on="listeners"
        :items="items"
        item-value="type"
        item-text="label"
        :value="computedValue"
        :clearable="true"
        @input="onInput"
    />
</template>

<script>

export default {
    name: 'GenderSelectInput',
    props: {
        value: [ String ],
        items: {
            type: [ Array ],
            required: true,
        }
    },
    data: vm => ({
        localValue: vm.value,
    }),
    methods: {
        onInput(value) {
            // Retira as mensagens de erro caso o input não formatado tenha algum valor
            
            this.$emit('input', value ? value : null)
        },

        focus() {
            this.$refs.input && this.$refs.input.focus()
        },
    },
    computed: {
        computedValue() {
            return this.localValue
        },

        /**
         * Extrai todos os listeners deste componente, exceto os que estamos sobrescrevendo
         */
        listeners() {
            const { input, ...listeners } = this.$listeners
            return listeners
        },
        /**
         * Extrai todas as props deste componente, exceto as que estamos sobrescrevendo
         */
        attrs() {
            const { value, ...attrs } = this.$attrs
            return { ...attrs }
        },
    },
    watch: {
        value(value) {
            this.localValue = value
        },
    },
}
</script>

<style lang="scss">
.cp-select-input {
    .v-input__append-outer {
        margin-top: 6px !important;
    }
}
</style>