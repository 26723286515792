var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "cp-select-field",
    _vm._g(
      _vm._b(
        {
          attrs: {
            items: _vm.items,
            "item-value": "type",
            "item-text": "label",
            value: _vm.computedValue,
            clearable: true
          },
          on: { input: _vm.onInput }
        },
        "cp-select-field",
        _vm.attrs,
        false
      ),
      _vm.listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }