import SharedBaseModel from '@/shared/models/BaseModel'
import BaseModel from '@/models/BaseModel'
import _ from 'lodash'

/**
 * Generaliza funções comuns em diversos forms e fieldsets
 *
 * @param {BaseModel} ModelClass indica sobre qual modelo o form vai fazer alterações
 * @param {string} [first_field] indica qual campo do form é o primeiro (opcional) (útil para autofocus())
 */
export default (ModelClass, first_field) => ({
    created() {
        // Se não tem valor, instancia uma nova instância da classe em questão
        if (!this.localValue)
            this.localValue = new ModelClass
    },
    mounted() {
        this.focus()
        this.localValue.untouch()
    },
    props: {
        // Substituir no form para garantir que só receba o modelo correto
        value: {
            type: [BaseModel, SharedBaseModel],
        },

        isFormValid: Boolean,
        loading    : Boolean,
        disabled   : Boolean,
    },
    data: vm => ({
        errorMessages: {},
        localValue: null,
    }),
    methods: {
        async submit() {
            // Se tem verificações antes da submissão, executa
            if (typeof this.presubmit == 'function' && !(await this.presubmit()))
                return

            // Habilita a validação das propriedades do modelo e valida
            let isModelValid = await this.localValue.validate()

            if (!isModelValid)
                return

            this.$emit('submit', this.localValue)
        },

        focus() {
            this.$nextTick(() => {
                this.$refs[first_field] && this.$refs[first_field].focus()
            })
        },

        getErrorMessages(field) {
            // Pega erros de validação locais
            let modelErrorMessages = this.localValue.errors[field]
            // Pega erros de validação da resposta do servidor
            let apiErrorMessages   = this.errorMessages[field]

            // Mostra somente um conjunto de erros
            return _.size(apiErrorMessages) ? apiErrorMessages : modelErrorMessages
        },

        /**
         * Limpa as mensagens de erros do servidor para um determinado campo
         *
         * @param {string} field campo a ser limpo (aceita dot syntax)
         */
        clearErrorMessages(field) {
            this.$set(this, `errorMessages[${field}]`, null)
        },
    },
    computed: {
        /**
         * Avalia se o form está valido localmente, atualiza via emit
         */
        isFormValidLocal: {
            get() {
                return this.isFormValid
            },
            set(value) {
                this.$emit('update:isFormValid', value)
            },
        },
    },
    watch: {
        value: {
            immediate: true,
            handler(value) {
                this.localValue = value
            },
        },
        localValue(value) {
            this.$emit('input', value)
        },
    },
})